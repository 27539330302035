<script setup lang="ts">
import { useMainStore } from '../store/index.ts';

const config = useConfig();

const store = useMainStore();
const darkMode = store.darkMode;
</script>

<template>
    <Submenu
        v-if="config.community"
        :links="[{
            header: 'terminology.header',
            name: 'terminology',
            icon: 'flag',
            condition: config.terminology.enabled && (config.terminology.published || $isGranted('terminology')),
        }, {
            header: 'inclusive.header',
            name: 'inclusive',
            icon: 'book-heart',
            condition: config.inclusive.enabled,
        }, {
            header: 'calendar.header',
            name: 'calendar',
            namesExtra: ['calendarDay'],
            icon: 'calendar-star',
            condition: config.calendar && config.calendar.enabled,
        }, {
            header: 'names.header',
            name: 'names',
            icon: 'signature',
            condition: config.names && config.names.enabled && (config.names.published || $isGranted('names')),
        }, {
            header: 'census.header',
            name: 'census',
            icon: 'user-chart',
            condition: config.census.enabled,
        }, {
            header: 'people.header',
            name: 'people',
            icon: 'user-friends',
            condition: config.people.enabled,
        }, {
            header: 'contact.team.nameShort',
            name: 'team',
            icon: 'collective-logo.svg',
            iconInverse: darkMode,
        }, {
            header: 'workshops.header',
            name: 'workshops',
            icon: 'chalkboard-teacher',
            condition: config.workshops?.enabled ?? false,
        }]"
    />
</template>
